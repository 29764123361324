<template>
  <div
    class="w-full container flex flex-col md:flex-row items-center justify-between px-5 pt-20 text-gray-700"
  >
    <div class="w-full">
      <div
        class="text-5xl mb-5 flex justify-start items-center text-left font-dark font-bold"
      >
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 text-green-700 h-10 w-10"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg> -->
        <span>Tu hora ha sido reservada</span>
      </div>
      <div
        class="bg-white border border-gray-200 px-2 py-2 rounded-md mb-4 flex justify-start items-start"
      >
        <div
          v-if="data && data.doctor"
          class="flex flex-col justify-center pl-2 items-start"
        >
          <span class="font-semibold text-xl mb-5 text-gray-700"
            >Detalles de la reserva</span
          >

          <span class="font-semibold text-md text-gray-900">
            <span class="font-bold text-gray-500 mr-2">Nombre paciente:</span>
            <span class="font-normal"
              >{{ capitalize(user.names) }}
              {{ capitalize(user.lastnames) }}</span
            >
          </span>
          <span class="font-semibold text-md text-gray-900">
            <span class="font-bold text-gray-500 mr-2">Nombre doctor:</span>
            <span class="font-normal"
              >{{ capitalize(data.doctor.names) }}
              {{ capitalize(data.doctor.lastnames) }}</span
            >
          </span>
          <span class="font-semibold text-md text-gray-900">
            <span class="font-bold text-gray-500 mr-2">Especialidad:</span>
            <span class="font-normal">{{ data.doctor.doctor.specialty }}</span>
          </span>
          <span class="font-semibold text-md text-gray-900">
            <span class="font-bold text-gray-500 mr-2">Fecha de reserva:</span>
            <span class="font-normal">{{
              formatDateNative(data.start_date)
            }}</span>
          </span>
          <span class="font-semibold text-md text-gray-900">
            <span class="font-bold text-gray-500 mr-2">Hora:</span>
            <span class="font-normal">{{
              formatDateNativeHour(data.start_date)
            }}</span>
          </span>
          <span class="font-semibold text-md text-gray-900">
            <span class="font-bold text-gray-500 mr-2">Valor reserva:</span>
            <span class="font-normal">{{ chileanPrice(data.price) }}</span>
          </span>
          <span class="font-semibold text-md text-gray-900">
            <span class="font-bold text-gray-500 mr-2"
              >Modalidad de atención:</span
            >
            <span class="font-normal">{{ data.text }}</span>
          </span>
          <div class="mt-8 mb-2 text-left leading-6">
            <strong>IMPORTANTE:</strong> en caso de que tu consulta sea
            realizada en modalidad de telemedicina, el doctor enviará un enlace
            a tu email minutos antes de la consulta. Solo debes hacer clic en el
            mismo y, en segundos, estarás en contacto con tu doctor. Si tu
            consulta es en modalidad presencial, recuerda llegar 10 minutos
            antes a la dirección mencionada.
          </div>
        </div>
      </div>
      <div class="w-full flex justify-start items-center">
        <a
          v-if="data && data.receipt_url"
          @click="goTo('/home')"
          class="underline text-gray-700 hover:text-gray-800 font-bold cursor-pointer"
        >
          Ir a mis reservas
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseService from '@/services/purchaseService';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Return',
  data() {
    return {
      data: null,
      tries: 10,
    };
  },
  methods: {
    ...mapActions('loading', ['change']),
    capitalize(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    },
    chileanPrice(price) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(price).replace(/,/g, '.');
    },
    formatDate(date) {
      moment.locale('es');
      let date_temp = moment(date, 'DD/MM/YYYY HH:mm');
      date_temp.locale(false);
      return date_temp.format('LLLL A');
    },
    setup_return(purchase) {
      this.change(true);
      this.data = null;
      if (this.tries > 0) {
        PurchaseService.return(purchase)
          .then(response => {
            this.change(false);
            this.data = response.data;
          })
          .catch(() => {
            setTimeout(() => {
              this.setup_return(purchase);
            }, 1000);
          });
      } else {
        this.$router.replace('/');
      }
    },
    goTo(route) {
      this.$router.replace(route);
    },
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'DD-MM-YYYY HH:mm');
      date_temp.locale(false);
      return (
        date_temp.format('LLLL').split('de')[0] +
        ' de ' +
        date_temp.format('LLLL').split('de')[1]
      );
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'DD-MM-YYYY HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm') + ' hrs';
    },
  },
  watch: {
    '$route.params.purchase': {
      handler: function(purchase) {
        this.setup_return(purchase);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>

<style scoped>
.error-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.error-link {
  cursor: pointer;
  color: #e5e7eb;
  text-decoration: underline;
}
.error-link:not(:last-child) {
  margin-right: 1em;
}
</style>
